.genresPage
  ul
    display flex
    flex-flow row wrap
  li
    width 20%
    min-width 130px
    box-sizing border-box
    padding-right 30px
    margin-bottom 15px

  .title
    font-weight 500
    font-size 14px
  h1
    font-size 24px
  h2
    font-size 16px
.kinetic
  position relative
  height gridWidth(4)

  .kineticLeft
  .kineticRight
    position absolute
    width gridWidth(1)
    height 100%
    top 0

    display flex
    justify-content center
    align-items center

    background lightness($palette1, 85%)
    color white
    border-radius 4px
    z-index 2
    opacity 0

    user-select none
    cursor pointer
    &:hover
      background $palette1
  .kineticLeft
    pointer-events none
    left 0
    .is-scroll-left&
      opacity 1
      pointer-events auto
  .kineticRight
    pointer-events none
    right 0
    .is-scroll-right&
      pointer-events auto
      opacity 1

  @media (max-width $breakpoint2)
    .kineticLeft
      display none
    .kineticRight//полупрозрачная плашка справа на мобилках, чтобы подсказать что можно скролить
      background linear-gradient(to right, transparent, $palette4)
      font-size 0
      pointer-events none
      .is-scroll-left&
        display none


  .kineticCenter
    overflow hidden
    height 100%
    border-radius 4px

  .kineticList
    display flex
    flex-flow column wrap
    justify-content space-between
    height 100%
    li
      margin-right gridWidth(0, 1)
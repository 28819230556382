$buttonSize = 19px
menuSprite2Crop(x,y)
  sprite2CropFromSet(($buttonSize $buttonSize), x, y)

.widget.genres
  li
    display block
    height 40px
    border-bottom 1px solid $palette6
    text-overflow ellipsis
    overflow hidden
    @extends $borderBottom
    &:last-child
      border-bottom none
    a
      line-height @height
      font-weight 500
      font-size 16px
      white-space nowrap
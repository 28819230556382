$palette1 = #EE4E4E
$palette2 = #BE2B2B
$palette3 = #FFFFFF
$palette4 = #F0F1F2
$palette5 = #2B2B2B
$palette6 = #CACACA
$palette7 = #BE2B2B

$gridSet = 85px 20px
gridWidth(a,b=null)
  gridWidthFromSet($gridSet, a, b)

$layoutWidth = gridWidth(12)
$leftSpanWidth = gridWidth(3)
$rightSpanWidth = gridWidth(2)
$mainSpanWidth = gridWidth(7)
$playerPosition = bottom

$breakpoint1 = gridWidth(10)
$breakpoint2 = gridWidth(7)

$borderBottom
  border-bottom 1px solid $palette6
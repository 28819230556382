.cover
  display block
  position relative
  width gridWidth(2)
  height @width

  border-radius 4px
  overflow hidden
  font-size 0//убираем расстояние между li элементами
  text-decoration none

  img
    width 100%
    height @width
    border-radius 4px
  .title
    display flex
    align-items flex-end
    position absolute
    left 0
    right 0
    bottom 0
    top 66%
    box-sizing border-box
    padding 12px 11px
    background linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .45) 100%)
    line-height 1em
    font-size 14px
    font-weight bold

  .bigCovers &
    width gridWidth(2)
    height @width
    .title
      color white

  .gridCovers &
    width 165px
    height @width
    .title
      color white

  .gridCovers &
  .widthFit &
    @media (max-width 375px)
      width 'calc(50vw - %s)' % (($containerSidePadding*3 + 10px)/2)
      height @width

  .mediumCovers &
    width 140px
    height @width
    .title
      color white
      opacity 0
      transition opacity 0.3s linear
    &:hover
      .title
        opacity 1

  .rowCovers &
    width 100%
    height gridWidth(1)
    margin 5px 0
    display flex
    flex-flow row nowrap

    img
      width 80px
      height @width
      flex 0 0 @width
    .title
      position static
      height 100%
      background none
      align-items flex-start
      font-size 16px
      padding 5px 18px

  &.circles
    img
      border-radius 95px
$mobileHeight = 140px

header
  height 88px
  background $palette7
  .container
    align-items center
  .burgerButton
    width 35px
    height 35px
    position relative
    display none
    flex-flow column nowrap
    justify-content space-around
    box-sizing border-box
    padding 10px
    background #8F5AFF
    border-radius 4px
    margin-left 20px

    .line
      display block
      background #ecf0f1
      width 100%
      height 2px
      transition all 0.4s
      -webkit-transition all 0.4s
      -moz-transition all 0.4s
      &.line-2
        transform translateY(.4px)

    &.active
      .line-1
        transform translateY(5px) translateX(0) rotate(45deg)
        -webkit-transform translateY(5px) translateX(0) rotate(45deg)
        -moz-transform translateY(5px) translateX(0) rotate(45deg)

      .line-2
        opacity 0

      .line-3
        transform translateY(-6px) translateX(0) rotate(45deg * -1)
        -webkit-transform translateY(-6px) translateX(0) rotate(45deg * -1)
        -moz-transform translateY(-6px) translateX(0) rotate(45deg * -1)
  .logo
    a
      text-decoration none
      display block
    @import "styl/header/logo.styl"
  .headerSearchbar
    @import "styl/header/search.styl"
  .spanRight
    display flex
    justify-content flex-end
    align-items center
    > *
      margin-left 20px
    @import "styl/header/right.styl"
  @media (max-width: $breakpoint2)
    height $mobileHeight
    .container
      flex-flow wrap
      .burgerButton
        display flex
      .headerSearchbar
        flex 1 1 70%
      .logo
      .additional
        flex-basis unset
        margin-left unset
        margin-right unset

.mobileShow.open
  display block!important
  position absolute
  top $mobileHeight
  background white
  left 0
  right 0
  padding 0 10px
  z-index 99
$spriteSize = 42px
$buttonSize = 20px
$fixSize = ($spriteSize - $buttonSize) / -2
$playerHeight = 67px

playerSprite2Crop(x,y,iFix=0,jFix=0)
  sprite2CropFromSet(($spriteSize $spriteSize),x,y,$fixSize+(iFix)px,$fixSize+(jFix)px)

$button
  display block

  width $buttonSize
  height $buttonSize
  //flex-shrink 0

  cursor pointer

  background-image url(images/sprite.svg)
  background-repeat no-repeat
  @media (min-width $breakpoint2)
    &:hover
      opacity .8

body
  margin-bottom $playerHeight

.player-controls
  position fixed
  {$playerPosition} 0
  left 0
  right 0
  z-index 9999

  display flex
  flex-flow row wrap
  justify-content center
  align-items center

  //height $playerHeight

  background $palette3
  box-shadow 0 4px 20px rgba(0, 0, 0, 0.3)

  .player-container
    @extends .container
    display flex
    flex-flow row nowrap
    position relative

  .group
    display flex
    flex-flow row nowrap
    justify-content center
    align-items center
    height $playerHeight
    &.basic
      @extend .container .spanLeft
    &.track-name
      @extends .container .spanMain
      justify-content space-between
      position relative

      .inited&
        display none
    &.additional
      @extends .container .spanRight


  .prev
    @extends $button
    playerSprite2Crop(0,0)
    &:active
      playerSprite2Crop(0,1)


  .next
    @extends $button
    playerSprite2Crop(3,0)
    &:active
      playerSprite2Crop(3,1)

  .download
    .dl
      @extends $button
      playerSprite2Crop(6,0)
      &:hover
      &:active
        playerSprite2Crop(6,1)

    .dl:not([href])
    .inited&
      opacity 0
      filter unquote('alpha(opacity=0)')


  .play
    @extends $button
    playerSprite2Crop(1,0)
    margin-left 30px
    margin-right @margin-left
    &:active
      playerSprite2Crop(1,1)

    .playing&
      display none

  .pause
    @extends $button
    playerSprite2Crop(2,0)
    margin-left 30px
    margin-right @margin-left
    &:active
      playerSprite2Crop(2,1)

    .playing&
      display block

  .mute
    @extends $button
    margin-right 10px
    playerSprite2Crop(8,0,-3)
    &.active
      playerSprite2Crop(8,2,-2)

  .repeat
    @extends $button
    playerSprite2Crop(4,1,-2)
    &.active
      playerSprite2Crop(4,0,-2)

  .shuffle
    @extends $button
    playerSprite2Crop(5,1,-2)
    margin-left 30px
    margin-right @margin-left
    &.active
      playerSprite2Crop(5,0,-2)

  .track-name
    .inited&
      display none

  .playerImg
    width 40px
    height @width
    flex 0 0 @width
    img
      border-radius 4px
      width 100%

  .songName
    display flex
    flex-flow column nowrap
    flex 1
    min-width 0
    margin-left 15px
    a
      text-decoration none
      color black
  .artist
    font-weight bold
    font-size 14px
  .track
    font-size 13px
  .artist
  .track
    white-space nowrap
    overflow hidden
    text-overflow ellipsis

  .time
    font-size 12px
    opacity .6
    
  .link
    line-height 19px
    padding 2px 10px
    margin-left 13px

    color white
    font-size 16px
    background $palette1
    border-radius 999px

    //display none
    //&[herf]
    //  display block

  .progress
    position absolute
    height 10px
    width 100%
    bottom 0
    z-index 1//some magic

    .seek-bar
    .play-bar
      position absolute
      left 0
      height 3px
      border-radius 6px
      cursor pointer
    .seek-bar
      background #d4c7c7
      width 100%

      //делаем кликабельную область больше
      padding 5px 0
      margin -5px 0 -2px//margin-bottom 3px
      background-clip content-box
    .play-bar
      background $palette1

  .volume-bar
  .volume-bar-value
    height 4px
    border-radius 6px
    cursor pointer
  .volume-bar
    background #d4c7c7
    width 75px
    position relative

    //делаем кликабельную область больше
    padding 5px 0
    margin -5px 0
    background-clip content-box
  .volume-bar-value
    position absolute
    left 0
    width 90%
    background $palette1
    transition width 80ms linear,height 80ms linear



@charset "utf-8"

@import "_common/styl/suggests"
@import "../suggests"
@import "../swiper.styl"

@import "_common/styl/index.styl"

.hide
  display none !important

header
  .burgerButton
    background #FFF
    .line
      background $palette2
  @media (max-width: $breakpoint2)
    .logo
      img
        width 170px
    .container
      .burgerButton
        order 4
      .headerSearchbar
        order 3
  @media (max-width 350px)
    .logo
      img
        width 140px

.desktop-sidebar .widget h3
  font-size 18px
.desktop-sidebar-right .widget
  margin-top 9px
.spanMain
  h1
    margin-top 0
    line-height 40px

.songs
  .item
    .playOther
      width 32px
      height @width
      background url("/images/playOther.svg") 50% 50% no-repeat
      margin-left 10px
      a
        display inline-block
        height 100%
        width 100%
    .songName
      margin-left 10px
    .download
      .dl
        display block
        width 32px
        height @width
        background url(/images/dl_zvuk.svg) 50% 50% no-repeat
        &:hover
          background url(/images/dl_zvuk.svg) 50% 50% no-repeat

.spanRight
  .topMusicLinks
    display flex
    align-items center
    width 100%
    @media (max-width 1030px)
      flex-direction column
      align-items start
    @media (max-width 700px)
      flex-direction row
      align-items start
      justify-content flex-end
    a
      display flex
      align-items center
      height 30px
      margin-right 35px
      @media (max-width 1030px)
        &:first-child
          margin-bottom 5px
          img
            margin-right 12px
      @media (max-width 500px)
        margin-right 0
        &:first-child
          margin-right 10px
      img
        margin-right 6px
      span
        font-weight bold
        font-size 16px
        line-height 19px
        color #fff
        border-bottom 1px solid #fff
.headDlPage
  .songs
    .item
      background #fff
      border-radius 4px
      height 52px
      padding 6px
      .duration,
      .download
        display none
.widget
  .morePin
    display flex
    justify-content center
    align-items center
    background $palette1
    h3
      font-weight bold
      font-size 16px
      line-height 16px
      width 80px
      margin 0
      color $palette4
    span
      font-size 60px
      color $palette4
      margin-top -20px
      width 40px
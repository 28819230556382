@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'swiper/css/grid';


    .swiper
        height 400px
    .swiper-button-next,
    .swiper-button-prev
        background #f8b9b9
        color #fff
        width 85px
        height 100%
        top 0
        margin 0
        --swiper-navigation-sides-offset 0
        --swiper-navigation-size 33px
        &:hover
            background #ee4e4e
    .swiper-slide
        width 190px
        height 190px
        li
            width 100%
            height 100%
            .cover
                width 100%
                height 100%
.mobile
    .swiper-button-next,
    .swiper-button-prev
        display none

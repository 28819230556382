.songs .item
  display flex
  flex-flow row nowrap
  align-items center
  width 100%
  height 60px
  box-sizing border-box
  padding 11px 6px
  @extends $borderBottom
  &:hover
    background #fff
    cursor pointer
  .playlistImg
    display flex
    justify-content center
    align-items center
    width 40px
    height @width
    position relative
    flex 0 0 @width
    img
      width 100%
      height 100%
      border-radius 4px

    .play
      position absolute
      width 20px
      height @width
      top 10px
      left 10px
      background-image url(images/sprite.svg)
      background-repeat no-repeat
      playerSprite2Crop(1,2)

  &.active
    background #fff
    .play
      playerSprite2Crop(2,2)
    &.pause
      .play
        playerSprite2Crop(1,2)
    &.error
      .play
        position relative
        background url(images/refresh.png) 50% 50% no-repeat

  &.load
    .play
      width 20px
      height 20px
      background transparent url(images/spiner.gif) 50% 50% no-repeat
      border-radius 11px

  .download
    .dl
      @extends $button
      playerSprite2Crop(6,0)
      &:hover
      &:active
        playerSprite2Crop(6,1)

  .songName
    flex 1
    min-width 0
    margin-left 15px
    > div
    > a
      display flex
      flex-flow column nowrap
      margin-left 0
      text-decoration none
    .artist
      font-weight bold
      font-size 14px
    .track
      font-size 13px
    .artist
    .track
      white-space nowrap
      overflow hidden
      text-overflow ellipsis

  .duration
    font-size 12px
    margin-right 10px
    margin-left 5px
.gridPage
  .grid
    display flex
    flex-flow row wrap
    justify-content space-between
    align-items center
    .cover
      margin-bottom 15px
.module-layout.error
  margin-top 30px
  .query
    font-size 20px
    color $palette1
$containerSidePadding ?= 10px//если не определено

body
  display flex
  flex-flow column nowrap
  background $palette4
  background-size cover
  background-attachment fixed
  font-family "Roboto", Arial, Helvetica, sans-serif;
  .content
    display flex
    flex-flow row nowrap
    justify-content space-between
    header
      display flex
    .container
      padding-top 10px

@import "_common/styl/header/index.styl"

.container
  width 100%
  max-width $layoutWidth+$containerSidePadding*2
  height 100%

  margin 0 auto
  padding 0 $containerSidePadding
  box-sizing border-box

  display flex
  flex-flow row nowrap
  justify-content space-between
  .spanLeft
  .spanRight
  .spanMain
    flex-grow 1
    flex-shrink 1
    min-width 0//some fucking magic
  .spanLeft
    flex-basis $leftSpanWidth
    max-width $leftSpanWidth
    margin-right gridWidth(0,1)
  .spanRight
    flex-basis $rightSpanWidth
    max-width $rightSpanWidth
    margin-left gridWidth(0,1)
  .spanMain
    flex-shrink 0.8
    flex-basis $mainSpanWidth
    //max-width $mainSpanWidth
a
  color black
  text-decoration none
  &:hover
    color $palette2
    transition color 0.3s ease-out

.button
  display inline-flex
  align-items center
  height 36px
  color white
  text-align center
  font-size 16px
  line-height 36px
  background $palette1
  border-radius (@height / 2)px
  padding-left 30px
  padding-right @padding-left
  font-weight 500
  &:hover
    color white
  &.listen
    padding-left 25px
    &:before
      content ''
      display inline-block
      width 20px
      height 20px
      top 10px
      left 10px
      background-image url(images/sprite.svg)
      background-repeat no-repeat
      background-position -53px -95px
      margin-right 10px
    &.play:before, &.playing:before
      background-position -95px -95px

.mobile .mobileHide
  display none

h1,h2,h3,.h3,h4,h5,h6
  line-height 1.2em

.collectionButtonLine
  margin-bottom 10px
.titleLine
  margin-bottom 10px


.shared_buttons
  display flex
  position relative
  align-items center
  p
    margin 0 10px
  .show_share
    color #fff
    background-color $palette1
    border-radius 25px
    text-align center
    line-height 23px
    display none
    min-width 106px
    &.active
      background-color $palette1
      color #fff
      margin-bottom 13px
    #share
      min-width 200px
  @media (max-width 500px)
    margin-top 0
    p
      display none
    #share
      min-width calc(100vw - 20px)
      margin-top 15px
      margin-right 0 px
    .ya-share2__container_size_m
      .ya-share2__list
        display flex
        justify-content space-between
        &.ya-share2__list_direction_horizontal
          margin-top -6px
        .ya-share2__icon
          width 30px
          height @width
          background-size unset
  @media (max-width 1030px) and (orientation landscape)
    .ya-share2__container_size_m
      .ya-share2__list
        .ya-share2__icon
          width 30px
          height @width
          background-size unset
  &.rollUp
    display block
    p
      display none
    #share
      position absolute
      right 0
      top 30px
      display none
      white-space nowrap
    .show_share
      display block

  .support
    display none
    height 32px
    margin-bottom 0
    @media (max-width 500px)
      height 36px
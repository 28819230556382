form
  display flex
  align-items flex-end

  border-bottom 2px solid #949494
  input#search
    border none
    outline none
    background transparent
    font-size 18px
    line-height 25px
    width 100%

  .search_button
    border none

    width 17px
    height @width
    flex-shrink 0

    padding 0
    margin 0 5px 7px
    background-image url(images/sprite.svg)
    background-color inherit
    background-repeat no-repeat
    playerSprite2Crop(2,6,11,12)
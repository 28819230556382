.suggestContainer
  left 0
  width 100%
  z-index 999

.tablet
  .suggestContainer
    width 100%
.mobile
  .suggestContainer
    max-height calc(100vh - 150px)
.tracksHeader
  display flex
  flex-flow row wrap
  align-items flex-start
  margin-top 0
  margin-bottom 15px
  h1
    margin-top 20px
    margin-bottom 10px
    flex 1 1 100%
  img
    width 140px
    height @width
    border-radius 4px
    margin-top 10px
    margin-right 20px
    margin-bottom 10px
  .description
    display flex
    flex-flow column nowrap
    flex 1
    margin-top 10px
  .collectionButtonLine
    display flex
    flex-flow row nowrap
    justify-content space-between
    align-items baseline
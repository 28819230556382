$marginBottom = 30px
footer
  margin-top 20px
  @media (max-width: $breakpoint2)
    margin-bottom 20px
  h3, .h3,
  h4,
  h5,
  p
    margin-top 0
    margin-bottom 0
  h4
    margin-bottom 4px
  .container
    color #191925
    font-size 14px
  .seo-text
    font-weight bold
    line-height 18px
    opacity .6
  .mail
    margin 30px 0

.hide
  display none !important

form
  position relative

.suggestContainer
  position absolute
  top 100%
  left 20px
  background white
  width 83.4%
  box-shadow 0 3px 15px rgba(0, 0, 0, 0.25)
  border-radius 4px
  z-index 10
  max-height calc(100vh - 250px)
  overflow-y auto
  scrollbar-color #191925 #000
  scrollbar-width thin
  .category
    margin 10px 15px 0
    border-bottom 1px solid #D5D5D5
    &:first-child
      margin 20px 15px 0
    &:last-child
      border-bottom none
      margin-bottom 10px
    .type
      font-size 11px
      text-transform uppercase
      letter-spacing 1px
      padding-left 5px
      font-weight bold
      color #858383
    .suggest
      display flex
      justify-content space-between
      align-items center
      font-size 16px
      padding-left 5px
      margin-bottom 5px
      height 35px
      color #000
      cursor pointer
      border-radius 6px
      &:hover
        background #e5e5e5
      &.active
        background gray
      span
        display block
        text-overflow ellipsis
        white-space nowrap
        overflow hidden
      img
        width 35px
        height @width
        min-width @width
        border-radius 6px


.tablet
  .suggestContainer
    width 79%
.mobile
  .suggestContainer
    left 0
    width 100%
    max-height calc(100vh - 150px)
.headDlPage
  h1
    font-size 24px
    margin 22px 0
    font-weight bold
    width 100%
    @media (max-width $breakpoint2)
      line-height 25px
  .songs.oneSong
    margin-bottom 15px
    li
      border-bottom none
  .descriptionItem
    display flex
    flex-flow row wrap
    justify-content space-between
    align-items center
    font-size 14px
    .item
      display flex
      flex-flow row wrap
      margin 10px 0
      span
        font-weight 500
        margin-right 15px
    .button
      padding 0 18px
      margin 10px 0
      & span
        margin 0 0 0 9px
        @extends $button
        playerSprite2Crop(6,2)
.relatedDl
  margin-top 62px
  h3, .h3
    margin-top 0
    margin-bottom 20px
    font-size 24px
    line-height @font-size
    font-weight 600
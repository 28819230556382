.widget
  margin-top 20px
  margin-bottom @margin-top
  &:first-child
    margin-top 0
  &:last-child
    margin-bottom 0
  .header
    display flex
    align-items center
    overflow hidden
    @media (max-width $breakpoint2)
      flex-wrap wrap
    .mobile &
      align-items baseline
      justify-content space-between
    .shared_buttons
      margin-left auto
    h1,h2,h3,.h3,h4
      &.title
        margin 0
        margin-right 15px
        @media (max-width 360px)
          margin 0
          margin-right 5px
  a
   text-decoration none

  h1,h2,h3,.h3,h4
    &.title
      font-weight bold
      line-height 1.2em
      margin 0
      margin-bottom 15px
      padding 0
      @media (max-width $breakpoint2)
        line-height 1.1em
  h4
    font-size 18px
  h3, .h3
    font-size 24px

  .mediumCovers
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content space-between
    li
      margin 0 0 15px

  .rowCovers
    li
      @extends $borderBottom

  .moreButton
    display inline-block
    height 23px
    padding 0 12px
    line-height @height
    background $palette1
    border-radius 15px
    margin 10px 0
    color white
    font-weight 500
  .shared_buttons
    margin 10px 0
  @media (max-width: $breakpoint2)
    h4
      font-size 16px
    h3, .h3
      font-size 19px
.titleLine
  display flex
  align-items start
  line-height 40px
  @media (max-width $breakpoint2)
    flex-direction column
  .shared_buttons
    margin-left auto
    min-width 305px

.seeAlso
  margin-top 20px
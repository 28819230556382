.radio
  .item
    i
      position absolute
      width 20px
      height @width
      top 73px
      left @top
      background-image url(images/sprite.svg)
      background-repeat no-repeat
      playerSprite2Crop(1,2)
      @media (max-width: 320px)
        top 60px
        left @top

      display none
    &:hover i
    &.pause i
    &.play i
      display block
    &.play i
      playerSprite2Crop(2,2)
    &.loading
      .cover
        i
          width 22px
          height 22px
          border-radius 11px
          background transparent url(images/spiner.gif) 50% 50% no-repeat
          background-position 0 0
  .rowCovers .item
    i
      top 30px
      left @top


$buttonSize = 19px
menuSprite2Crop(x,y)
  sprite2CropFromSet(($buttonSize $buttonSize), x, y)

$iconArr = ('.menuIcon' '.notesIcon' '.starIcon' '.singerIcon' '.albumsIcon' '.radioIcon' '.audiobookIcon' '.tracklistIcon')

.widget.burger_menu
  li
    display block
    height 40px
    @extends $borderBottom
    &:last-child
      border-bottom none
    a
      display flex
      align-items center
      line-height @height
      font-weight 500
      font-size 18px
      &:before
        content ''
        display inline-block

        width $buttonSize
        height $buttonSize

        margin-right 5px

        background-image url(images/menuSprite.svg)
        background-repeat no-repeat
    for iconName, i in $iconArr
      {iconName}
        &:before
          menuSprite2Crop(i,0)

    &.active
      a
        color $palette1
      for iconName, i in $iconArr
        {iconName}:before
          menuSprite2Crop(i,1)
@media (max-width: $breakpoint1)
  header
  footer
  .content
    .spanRight
      display none
  .player-controls
    .player-container
      flex-wrap wrap
      padding-bottom 10px
    .group
      height 40px
      &.track-name
        flex 1 1 100%
        order -1
        position static
        height 50px
      &.basic
        justify-content flex-start
        flex 0 1 50%
        margin-right unset
      &.additional
        justify-content flex-end
        flex 0 1 50%
        margin-left unset
    .link
      display none
    .progress
      width calc(100% - 20px)
    .songName
      line-height 1.05em
@media (max-width: $breakpoint2)
  header
    .spanRight
      display flex
  footer
    .container
      flex-direction column
      .spanLeft
      .spanMain
        flex-basis 0
        margin 0

  .content
    .spanMain
      flex-shrink unset
    .spanLeft
      margin 0
      width 0
      flex-basis 0
      > *
        display none